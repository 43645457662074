import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/polityka-prywatnosci/" className="sel">
              {" "}
              &gt; Polityka prywatności
            </a>
          </div>
          <div id="page_content">
            <h1 className="uc">Polityka prywatności</h1>
            <div className="wysiwyg">
              <p>
                JNTL Consumer Health (Poland) sp. z o.o. szanuje prywatność użytkowników, dlatego chcemy poinformować Cię w jaki sposób zbieramy, wykorzystujemy i udostępniamy informacje.   Niniejsza Polityka prywatności opisuje nasze praktyki związane z informacjami, które my lub nasi dostawcy zbieramy za pośrednictwem niniejszej strony internetowej lub aplikacji (nazywanej dalej &nbsp;„
                <strong>Usługą</strong>”), którą zarządzamy i kontrolujemy, a z ktorej następiło przekierowanie do niniejszej Polityki prywatności.  Przekazując nam dane osobowe lub korzystając z Usługi, potwierdzasz, że przeczytałeś i  zrozumiałeś niniejszą Politykę prywatności.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>KORZYSTANIE Z USŁUGI PRZEZ OSOBY NIELETNIE</strong>
              </p>
              <p>&nbsp;</p>
              <p>
              Usługa nie jest przeznaczona dla osób w wieku poniżej 18, w związku z czym prosimy osoby w takim wieku o nieprzekazywanie danych osobowych za pośrednictwem Usługi. Jeżeli Twoje dziecko przesłało nam swoje dane osobowe i chciałbyś je usunąć, prosimy o kontakt (zgodnie z opisem w sekcji „Jak się z nami skontaktować").
              </p>
             
              <p>&nbsp;</p>
              <p>
                <strong>ZBIERANIE INFORMACJI</strong>
              </p>
              <p>&nbsp;</p>
              <p>
              Możemy poprosić Cię o podanie danych osobowych, aby umożliwić Ci korzystanie z niektórych funkcji (takich jak newslettery, wskazówki/odsyłacze lub realizacja zamówień) lub udział w naszych akcjach (np. konkursy lub inne promocje).  Za każdym razem otrzymasz informację określającą konieczność lub fakultatywność podania danych.
              </p>
              <p>
              Możemy łączyć dane, które przekazujesz z innymi danymi, które otrzymaliśmy od Ciebie w Internecie oraz poza nim, włączając w to na przykład historię Twoich zakupów. Możemy je także łączyć z informacjami, które uzyskaliśmy o Tobie od innych spółek stowarzyszonych.
              </p>
              <p>
              Podając nam dane osobowe dotyczące innej osoby, potwierdzasz, że masz do tego upoważnienie, a my mamy zgodę tej osoby na wykorzystywanie tych informacji zgodnie z niniejszą Polityką prywatności.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>DANE WRAŻLIWE</strong>
              </p>
              <p>&nbsp;</p>
              <p>
              Za wyjątkiem wyraźnej prośby lub zachęty, prosimy o niewysyłanie nam, oraz nieujawnianie żadnych wrażliwych danych osobowych (takich jak np. numery ubezpieczenia społecznego, informacje związane z pochodzeniem rasowym lub etnicznym, poglądami politycznymi, religijnymi lub przekonaniami filozoficznymi, stanem zdrowia, życiem erotycznym lub orientacją seksualną, karalnością lub przynależnością do związku zawodowego lub które dotyczą danych biometrycznych lub genetycznych) za pośrednictwem Usługi lub w inny sposób.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>
                  AUTOMATYCZNE ZBIERANIE I WYKORZYSTYWANIE INFORMACJI
                </strong>
              </p>
              <p>&nbsp;</p>
              <p>
              Zarówno my, jak i nasi dostawcy usług możemy automatycznie zbierać określone informacje o użytkowniku i jego sposobach korzystania z Usługi.  Prosimy o zapoznanie się z treścią {" "}
                <a href="/polityka-cookies/">
                Polityki dotyczącej plików cookie
                </a>{" "}
                w celu uzyskania szczegółowych informacji dotyczących plików cookie i innych technologii monitorowania aktywności w sieci, które wykorzystujemy w ramach Usługi. Z wyjątkiem przypadków dozwolonych przez obowiązujące prawo, pliki cookie są umieszczane na dysku użytkownika dopiero po uzyskaniu jego zgody za pośrednictwem banera informującego o plikach cookie albo poprzez centrum preferencji. Polityka dotycząca plików cookie zawiera informacje o tym, jak zablokować te technologie.  
              </p>
              <p>
              Zarówno my, jak i nasi dostawcy usług możemy automatycznie gromadzić i wykorzystywać dane na różne sposoby, np:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Za pośrednictwem Twojej przeglądarki:</strong>
                    &nbsp;Niektóre informacje, takie jak adres Media Access Control (MAC), typ komputera (Windows lub Mac), rozdzielczość ekranu, nazwa i wersja systemu operacyjnego oraz typ i wersja przeglądarki internetowej, są gromadzone przez większość przeglądarek.  Jeżeli dostęp do Usługi uzyskujesz za pośrednictwem urządzenia mobilnego, możemy gromadzić podobne informacje, np. typ urządzenia i jego identyfikator.  Informacje te wykorzystujemy w celu zapewnienia prawidłowego funkcjonowania Usługi.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Adres IP:</strong>&nbsp;Adres IP to numer, który jest automatycznie przypisywany do komputera użytkownika przez dostawcę usług internetowych.  Adres IP jest identyfikowany i automatycznie rejestrowany w plikach dziennika serwera za każdym razem, gdy użytkownik odwiedza Usługę, wraz z czasem wizyty i odwiedzonymi stronami.  Rejestrowanie adresów IP jest standardową praktyką i jest wykonywane automatycznie przez wiele serwisów internetowych.  Adresy IP są wykorzystywane przez nas do takich celów jak obliczanie poziomów korzystania z Usługi, diagnozowanie problemów z serwerem i administrowanie Usługą.  Na podstawie adresu IP użytkownika możemy także wskazać jego przybliżoną lokalizację.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Informacje o urządzeniu:</strong>&nbsp;Aby zrozumieć, w jaki sposób korzystasz z naszej Usługi, możemy zbierać informacje o Twoim urządzeniu mobilnym, takie jak niepowtarzalny identyfikator urządzenia.
                  </p>
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                <strong>
                  W JAKI SPOSÓB WYKORZYSTUJEMY I UDOSTĘPNIAMY INFORMACJE
                </strong>
              </p>
              <p>&nbsp;</p>
              <p>
              Wykorzystujemy i udostępniamy informacje, które nam podajesz, w sposób określony w momencie ich zbierania.  Aby dowiedzieć się, jak można zrezygnować z niektórych naszych form wykorzystywania i udostępniania danych, przeczytaj sekcję "Wybór i dostęp" (poniżej).
              </p>
              <p>
              Tam, gdzie jest to wymagane przez obowiązujące prawo, w momencie gromadzenia Twoich danych osobowych uzyskamy Twoją zgodę na ich wykorzystanie.  Możemy również wykorzystywać informacje otrzymane od Ciebie lub na Twój temat w zakresie niezbędnym do wykonania umowy, wywiązania się z obowiązku prawnego (na przykład dotyczące naszych obowiązków w zakresie nadzoru nad bezpieczeństwem farmakoterapii) lub w celu realizacji naszych uzasadnionych interesów biznesowych.  Możemy również opierać się na innych podstawach prawnych, w szczególności w następujących celach:
              </p>
              <ul>
                <li>
                  <p>
                    zapewnienie funkcjonalności Usługi i realizacja Twoich
                    próśb.
                  </p>
                  <ul>
                    <li>
                      <p>
                        zapewnienie funkcjonalności Usługi i odpowiedniej
                        obsługi klienta;
                      </p>
                    </li>
                    <li>
                      <p>
                      	odpowiedź na Twoje zapytania i wykonywanie działań na Twoje życzenie, takich jak wysyłanie dokumentów lub powiadomień w formie wiadomości e-mail;
                      </p>
                    </li>
                    <li>
                      <p>
                      wysyłanie Ci ważnych informacji dotyczących naszych relacji z Tobą lub dotyczących Usługi, zmian dotyczących warunków, zasad i polityk i/lub innych informacji związanych z administracją.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
              Działania te umożliwią zarządzanie naszą relacją z Tobą, wynikającą z umowy i/lub realizowanie prawnych zobowiązań.
              </p>
              <p>&nbsp;</p>
              <p>Osiąganie naszych celów biznesowych.</p>
              <ul>
                <li>
                  <p>
                    do analizy danych, na przykład w celu poprawy wydajności
                    Usługi;
                  </p>
                </li>
                <li>
                  <p>
                  	do audytów, w celu sprawdzenia, czy nasze procesy wewnętrzne funkcjonują zgodnie z założeniami i czy są zgodne z wymogami prawa, regulacjamii lub zapisami wynikającymi z umowy;
                  </p>
                </li>
                <li>
                  <p>
                  do monitorowania potencjalnych oszustw i bezpieczeństwa, na przykład w celu wykrywania i zapobiegania cyberatakom lub próbom kradzieży tożsamości;
                  </p>
                </li>
                <li>
                  <p>do opracowywania nowych produktów i usług;</p>
                </li>
                <li>
                  <p>
                  	do poprawienia atrakcyjności, ulepszania lub modyfikowania naszej strony internetowej lub produktów i usług; 
                  </p>
                </li>
                <li>
                  <p>
                  	do identyfikacji trendów korzystania z Usługi, na przykład w celu zrozumienia, które części naszej Usługi są najbardziej interesujące dla użytkowników; i
                  </p>
                </li>
                <li>
                  <p>
                  do określania skuteczności naszych kampanii promocyjnych, abyśmy mogli dostosować je do potrzeb i zainteresowań naszych użytkowników.
                  </p>
                </li>
              </ul>
              <p>
              
Działania te umożliwią zarządzanie naszą relacją z Tobą, wynikającą z umowy, realizowanie prawnych zobowiązań i/lub ze względu na nasz uzasadniony interes.

              </p>
              <p>&nbsp;</p>
              <p>
                Analiza danych osobowych w celu zapewnienia spersonalizowanych
                usług.
              </p>
              <ul>
                <li>
                  <p>
                  	aby lepiej zrozumieć Twoje potrzeby, spersonalizować nasze kontakty z Tobą i dostarczać Ci informacje i/lub oferty dostosowanych do Twoich zainteresowań;
                  </p>
                </li>
                <li>
                  <p>
                aby lepiej zrozumieć Twoje preferencje i móc dostarczać Ci treści za pośrednictwem Usługi, które naszym zdaniem będą dla Ciebie istotne i interesujące.
                  </p>
                </li>
              </ul>
              <p>
              Spersonalizowane usługi zapewniamy po uzyskaniu Twojej zgody lub z uwagi na nasz uzasadniony interes.
              </p>
              <p>
                Ponadto, informacje zebrane za pośrednictwem Usługi możemy
                przekazywać:
              </p>
              <ul>
                <li>
                  <p>
                  naszym podmiotom stowarzyszonym w celach określonych w niniejszej Polityce prywatności.  Lista naszych podmiotów stowarzyszonych jest dostępna pod adresem &nbsp;
                    <a target="_blank" href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx">
                     https://investors.kenvue.com/financials-reports/sec-filings/default.aspx
                    </a>
                    &nbsp;- dostęp do kolejnych zgłoszeń Kenvue w Komisji Giełd Papierów Wartościowych.  JNTL Consumer Health (Poland) sp. z o.o. jest stroną odpowiedzialną za zarządzanie wspólnie wykorzystywanymi Danymi Osobowymi;
                  </p>
                </li>
                <li>
                  <p>
                naszym partnerom stanowiącym stronę trzecią, wraz z którymi oferujemy wspólnie reklamowane lub firmowane markami promocje;
                  </p>
                </li>
                <li>
                  <p>
                  	naszym zewnętrznym dostawcom, którzy świadczą usługi, takie jak hosting i moderowanie stron internetowych, hosting aplikacji mobilnych, analiza danych, przetwarzanie płatności, realizacja zamówień, dostarczanie infrastruktury, usługi IT, obsługa klienta, usługi poczty elektronicznej i bezpośredniego dostarczania poczty, usługi audytorskie i inne usługi, w celu umożliwienia im wykonania usług; oraz
                  </p>
                </li>
                <li>
                  <p>
                  zgodnie z obowiązującym prawem, zewnętrznym dostawcą usług w przypadku reorganizacji, fuzji, sprzedaży, zawiązania spółki joint-venture, cesji, przeniesienia lub innego dysponowania całością lub częścią naszej firmy, aktywów lub akcji (w tym w związku z bankructwem lub podobnym postępowaniem sądowym).
                  </p>
                </li>
                
              </ul>
              <p>
              Ponadto możemy wykorzystywać i udostępniać informacje, kiedy uznamy to za konieczne lub stosowne: (a) w celu przestrzegania procedury prawnej lub obowiązującego prawa, włączając w to prawo obowiązujące poza granicami Twojego kraju zamieszkania; (b) zgodnie z obowiązującym prawem, aby ustosunkować się do żądań ze strony władz publicznych i rządowych, w tym organy poza krajem Twojego zamieszkania; (c) w ramach wprowadzania w życie naszych warunków; oraz (d) aby chronić prawa, prywatność, bezpieczeństwo lub mienie nasze, i/lub prawa, prywatność, bezpieczeństwo lub mienie naszych spółek stowarzyszonych, Twoje lub innych osób.  Za Twoją zgodą możemy również w inny sposób wykorzystywać i udostępniać zebrane od Ciebie dane.
              </p>
              <p>
              Możemy wykorzystywać i udostępniać informacje, które zbieramy automatycznie, zgodnie z opisem w części „Automatyczne zbieranie i wykorzystanie informacji”.
              </p>
              <p>
              Ponadto, jeżeli jest to dozwolone przez obowiązujące prawo, możemy w dowolnym celu wykorzystywać i udostępniać informacje, które nie są danymi osobowymi.  Jeżeli połączymy informacje, których forma nie umożliwia identyfikacji tożsamości z informacjami, które to umożliwiają (np. poprzez połączenie imienia i nazwiska z lokalizacją geograficzną), będziemy traktować połączone informacje jako dane osobowe przez cały czas ich połączenia.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>WYBÓR I DOSTĘP</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Opcje wyboru dotyczące wykorzystania i udostępniania przez nas</strong>
                Twoich danych osobowych
              </p>
              <p>
              Dajemy Ci wybór w zakresie wykorzystania i udostępniania Twoich danych osobowych w celach marketingowych.  Możesz zrezygnować z:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Otrzymywania od nas komunikacji marketingowej</strong>:&nbsp;Jeżeli nie chcesz być odbiorcą komunikacji marketingowej w przyszłości, możesz z niej zrezygnować, kontaktując się z nami za pośrednictwem poczty elektronicznej &nbsp;
                    <a href="mailto:emeaprivacy@kenvue.com">
                    emeaprivacy@kenvue.com
                    </a>
                    &nbsp;. W swojej wiadomości prosimy podać imię i nazwisko, określić formę lub formy komunikacji marketingowej, których nie chcesz otrzymywać oraz adres, na który są one wysyłane.  Jeśli na przykład nie chcesz w przyszłości otrzymywać od nas e-maili z informacjami marketingowymi lub bezpośrednich wiadomości e-mail, powiadom nas o tym, podając przy tym swoje imię i nazwisko oraz adres e-mail lub adres pocztowy.  Ponadto możesz zrezygnować z otrzymywania od nas e-maili z informacjami marketingowymi, postępując zgodnie z zawartymi w nich instrukcjami rezygnacji z subskrypcji.
                  </p>
                </li>
                <li>
                  <p>
                    <strong> Otrzymywania od nas przypomnień</strong>:&nbsp;Jeżeli nie chcesz otrzymywać od nas w przyszłości przypomnień medycznych, możesz zrezygnować z ich otrzymywania, kontaktując się z nami za pośrednictwem poczty elektronicznej &nbsp;
                    <a href="mailto:emeaprivacy@kenvue.com">
                    emeaprivacy@kenvue.com
                    </a>
                    &nbsp;. W swojej wiadomości prosimy podać imię i nazwisko oraz adres e-mail lub numer telefonu, na który przypomnienia są wysyłane.
                  </p>
                </li>
                <li>
                  <p>
                   <strong>Udostępniania przez nas Twoich danych osobowych naszym
                    spółkom stowarzyszonym i partnerom zewnętrznym stanowiącym
                    strony trzecie :</strong> &nbsp;Jeżeli wcześniej wyraziłeś zgodę na otrzymywanie komunikacji marketingowej od naszych spółek stowarzyszonych lub partnerów zewnętrznych, możesz zrezygnować z udostępniania swoich danych osobowych tym podmiotom w celach bezpośredniego marketingu, kontaktując się z nami za pośrednictwem poczty elektronicznej  &nbsp;
                    <a href="mailto:emeaprivacy@kenvue.com">
                    emeaprivacy@kenvue.com
                    </a>
                    &nbsp;. W wiadomości do nas prosimy o zadeklarowanie, że nie chcesz abyśmy w przyszłości udostępniali Twoje dane osobowych naszym spółkom stowarzyszonym i/lub partnerom zewnętrznym w celach marketingowych, a także o podanie swojego imienia i nazwiska oraz adresu e-mail.
                  </p>
                </li>
              </ul>
              <p>
              Postaramy się dostosować się do Twojej prośby w możliwie najkrótszym terminie.  Zwróć uwagę, że w przypadku rezygnacji zgodnie z powyższymi opisami, nie będziemy w stanie usunąć Twoich danych osobowych z baz danych naszych spółek stowarzyszonych, którym już udostępniliśmy takie dane (tj. na dzień wdrożenia Twojej rezygnacji). Jednakże dołożymy należytej staranności, aby poinformować spółki stowarzyszonych o Twojej prośbie. Zwróć także uwagę, że w razie rezygnacji z otrzymywania od nas wiadomości marketingowych, możemy nadal wysyłać Ci ważne wiadomości dotyczące transakcji i administracji, z otrzymywania których nie możesz zrezygnować.
              </p>
              <p>
              <strong> Jak uzyskać dostęp, modyfikować lub usunąć swoje dane osobowe.</strong> 
              </p>
              <p>Jeżeli chcesz sprawdzić, skorygować, zaktualizować, ograniczyć lub usunąć swoje dane osobowe lub chcesz poprosić o otrzymanie elektronicznej kopii swoich danych osobowych w celu przesłania ich do innej firmy (w zakresie, w jakim te prawa Ci przysługują w świetle obowiązujących przepisów), prosimy o kontakt za pośrednictwem&nbsp;
                    <a href="mailto:emeaprivacy@kenvue.com">
                    emeaprivacy@kenvue.com
                    </a>
                    &nbsp;.Odpowiemy na Twoją prośbę tak szybko, jak to będzie możliwe w praktyce, ale nie później niż w ciągu miesiąca od jej otrzymania. W przypadku opóźnienia w udzieleniu przez nas odpowiedzi natychmiast Cię o tym poinformujemy i podamy datę odpowiedzi.  </p>
              <p>&nbsp;</p>
              <p>
                <strong>TRANSFER DANYCH ZA GRANICĘ</strong>
              </p>
              <p>&nbsp;</p>
              <p>
              Twoje dane osobowe mogą być przechowywane i przetwarzane w każdym kraju, w którym mamy oddział lub dostawców usług, a poprzez korzystanie z naszej Usługi lub poprzez wyrażenie na naszą rzecz zgody (w przypadku istnienia stosownych przepisów prawnych), Twoje dane mogą być przekazane do krajów poza swoim krajem zamieszkania, właczając w to Stany Zjednoczone, które mogą zapewniać inne przepisy dotyczące ochrony danych niż w Twoim kraju. Niemniej jednak stosujemy odpowiednie środki umowne i inne sposoby ochrony danych osobowych, gdy są one przesyłane do naszych spółek stowarzyszonych lub dostawców usług w innych krajach.
              </p>
              <p>
              Niektóre kraje spoza Europejskiego Obszaru Gospodarczego (EOG) są uznawane przez Komisję Europejską jako zapewniające odpowiedni poziom ochrony danych zgodnie ze standardami EOG (pełna lista tych krajów jest dostępna &nbsp;
                <a target="_blank" href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_pl">
                  tutaj
                </a>
                .  W przypadku przesyłania danych z EOG do krajów, które nie zostały wskazane jako zapewniające odpowiednią ochronę przez Komisję Europejską, zadbaliśmy o wdrożenie odpowiednich mechanizmów ochrony danych, w tym zapewnienie, że odbiorca jest związany Standardowymi klauzulami umownymi UE w celu ochrony danych osobowych.  Możesz uzyskać informację na temat tych środków, kontaktując się z naszym specjalistą ds. ochrony danych zgodnie ze wskazówkami w sekcji Jak się z nami skontaktować ( poniżej).
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>BEZPIECZEŃSTWO</strong>
              </p>
              <p>&nbsp;</p>
              <p>
              Staramy się wykorzystywać odpowiednie środki organizacyjne, techniczne i administracyjne, mające na celu ochronę kontrolowanych przez nas danych osobowych.  Niestety, żaden system transmisji danych przez internet ani system przechowywania danych nie jest w stanie zagwarantować 100% bezpieczeństwa.  Jeśli masz powody, by uważać, że Twoja interakcja z nami przestała być bezpieczna (np. jeżeli uważasz, że bezpieczeństwo Twojego konta u nas zostało naruszone), natychmiast powiadom nas o zaistniałym problemie, kontaktując się z nami zgodnie zgodnie z treścią sekcji, Jak się z nami skontaktować (poniżej).
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>OKRES PRZECHOWYWANIA DANYCH</strong>
              </p>
              <p>&nbsp;</p>
              <p>
              Przechowujemy Twoje dane osobowe przez okres niezbędny do zrealizowanie celów, dla których zostały uzyskane.  Okresy przechowywania ustalamy na podstawie następujących kryteriów:  (i) okres utrzymywania relacji z Tobą i świadczenia na Twoją   rzecz Usługi; (ii) ewentualne zobowiązania prawne, którym podlegamy; oraz (iii) czy przechowywanie danych jest wskazane w świetle obowiązującego prawa (np. w odniesieniu do obowiązującego okresu przedawnienia, postępowania sądowego lub dochodzenia regulacyjnego).
              </p>
             
              <p>&nbsp;</p>
              <p>
                <strong>
                  STRONY I USŁUGI PODMIOTÓW ZEWNĘTRZNYCH (STRON TRZECICH)
                </strong>
              </p>
              <p>&nbsp;</p>
              <p>
              Usługa może zawierać linki do stron podmiotów trzecich. Niniejsza Polityka prywatności nie dotyczy, jak również my nie jesteśmy odpowiedzialni za prywatność, informacje, a także praktyki żadnej ze stron trzecich, włączając w to dowolną ze stron trzecich zarządzających dowolną ze stron lub usług internetowych (w tym między innymi wszelkimi aplikacjami), które są dostępne za pośrednictwem tej Usługi lub do której ta Usługa zawiera link.  Dostępność lub uwzględnienie linka do dowolnej witryny lub usługi tego typu w niniejszej Usłudze nie oznacza aprobaty dla niej ze strony naszej, ani naszych spólek stowarzyszonych.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>JAK SIĘ Z NAMI SKONTAKTOWAĆ</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                JNTL Consumer Health (Poland) sp. z o.o.,&nbsp;z siedzibą pod adresem Ul. Iłżecka 24, 02-135 Warszawa, jest spółką odpowiedzialną za zbieranie, wykorzystywanie i udostępnianie danych osobowych w myśl niniejszej Polityki prywatności.
               </p>
              <p>&nbsp;</p>
              <p>
              Jeśli masz jakiekolwiek pytania dotyczących niniejszej Polityki prywatności skontaktuj się z nami za pośrednictwem wiadomości e-mail &nbsp;
                <a href="mailto:emeaprivacy@kenvue.com">
                emeaprivacy@kenvue.com
                </a>
                ,&nbsp; lub wyślij je tradycyjną pocztą na adres:
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>JNTL Consumer Health (Poland) sp. z o.o.</strong>
              </p>
              <p>
                <strong>Ul. Iłżecka 24</strong>
              </p>
              <p>
                <strong>02-135 Warszawa</strong>
              </p>
              <p>
                <strong>Polska</strong>
              </p>
              <p>
              Możesz także skontaktować się ze specjalistą ds. ochrony danych odpowiedzialnym za tę kwestię w kraju lub regionie użytkownika (jeżeli dotyczy) pod adresem &nbsp;
                <a href="mailto:emeaprivacy@kenvue.com">
                  emeaprivacy@kenvue.com.
                </a>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>SKŁADANIE SKARG DO ORGANU REGULACYJNEGO</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                Możesz wnieść skargę do organu nadzorczego właściwego dla
                Twojego kraju lub regionu. Kliknij&nbsp;
                <a target="_blank" href="https://jnj-my.sharepoint.com/personal/evanenge_its_jnj_com/Documents/Consumer Review Poland January 2021/tutaj">
                  tutaj
                </a>
                ,&nbsp; aby uzyskać dane kontaktowe do takiego organu. 
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>AKTUALIZACJE NINIEJSZEJ POLITYKI PRYWATNOŚCI</strong>
              </p>
              <p>&nbsp;</p>
              <p>
              Niniejsza Polityka prywatności może ulec zmianie.  Wszelkie zamiany w niniejszej Polityce prywatności wchodzą w życie w momencie publikacji aktualnej wersji Polityki prywatności dla tej Usługi.  Korzystanie z Usługi po wprowadzeniu zmian w Polityce prywatności oznacza ich akceptację.  Zalecamy regularne przeglądanie Polityki prywatności podczas odwiedzania Usługi. Ostatnia aktualizacja Polityki prywatności: <strong>02.06.2023</strong>
              </p>
           
             
              <p>&nbsp;</p>
            </div>
          </div>
          <footer>
            <div className="page_width">
              <div className="drug_alert"></div>
              <p
                style={{
                  "font-size": "10px",
                  "-webkit-text-align": "right",
                  "text-align": "right",
                  padding: "0 0 10px"
                }}
              >
                MAT/6769/03/2019
              </p>
            </div>
            <div className="footer_content">
              <div className="page_width">
                <ul id="footer_menu">
                  <li>
                    <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                  </li>
                  <li>
                    <a href="/polityka-cookies/">Polityka cookies</a>
                  </li>
                  <li>
                    <a href="/nota-prawna/">Nota prawna</a>
                  </li>
                  <li>
                    <a href="/mapa-strony/">Mapa strony</a>
                  </li>
                  <li>
                    <a href="/slowniczek/">Słowniczek</a>
                  </li>
                  <li>
                    <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                  </li>
                </ul>
                <div className="rel footer_info">
                  <br />
                  Podmiot odpowiedzialny: McNeil Products Limited <br />©
                  JNTL Consumer Health (Poland) sp. z o.o. 2023
                </div>
              </div>
            </div>
          </footer>{" "}
        </div>
      </div>
    );
  }
}

export default Page;
